<template>
  <div class="drivers-step2-content">
    <div class="bg-white">
      <div class="pl-24">
        <label class="require ml-10 text-18 text-main font-500">驾驶证主副页</label>
      </div>
      <div class="driver-card-wrap d-flex justify-space-between px-24 py-8">
        <tos-uploader
          class="driver-card"
          v-model="driverLicenseFileList"
          @afterUploaded="afterUploadedDriverCard"
          :max-count="1"
          bgType="jiashizheng"
        ></tos-uploader>
      </div>
    </div>

    <van-form class="px-12" ref="form">
      <div v-for="(item, key) in FormObj" :key="key">
        <van-field
          @click.prevent="['check'].includes(item.type) && showPopup(key)"
          :class="{ titleError: userVertifiedRef.reasonKeys.includes(key) }"
          :required="!!item.required"
          :readonly="['check'].includes(item.type)"
          v-model="userVertifiedRef[key]"
          v-if="!item.custom && !item.hidden"
          input-align="right"
          :name="key"
          :label="item.label"
          :placeholder="!disabled ? '请输入' : '识别中...'"
          :rules="item.rules"
          :type="item.type"
          :autosize="item.autosize"
          rows="1"
        >
          <template #label>
            <div :class="{ titleError: userVertifiedRef.reasonKeys.includes(key) }">
              <div>{{ item.label }}</div>
              <div class="font-12" v-if="userVertifiedRef.reasonKeys.includes(key)">
                不正确
              </div>
            </div>
          </template>
        </van-field>

        <TimeMachine
          v-if="key === 'driverLicenseEndDate' && !FormObj['driverLicenseEndDate'].hidden"
          :errorTitle="userVertifiedRef.reasonKeys.includes('driverLicenseEndDate')"
          v-model="timeDateRef"
          label="驾驶证"
          :yearSelects="[6, 10]"
        />
      </div>
    </van-form>

    <Popup v-model="FormObj.driveType.visible" round position="bottom">
      <ChecksPorps
        title="选择准驾车型"
        v-model="userVertifiedRef.driveType"
        :columns="FormObj.driveType.options"
        @confirm="e => handlePopConfirm(e, 'driveType')"
        @cancel="FormObj.driveType.visible = false"
      />
    </Popup>

    <Popup v-model="showDateRef" position="bottom">
      <div class="px-16 py-8">
        <van-button size="small" type="primary" class="h-28" @click="handleDateConfirm(null)">清空</van-button>
      </div>
      <DatetimePicker
        title=""
        type="date"
        :min-date="minDate"
        :maxDate="maxDate"
        v-model="currentDate"
        show-toolbar
        @confirm="handleDateConfirm"
        @cancel="showDateRef = false"
      />
    </Popup>

    <Popup v-model="showDateRef2" position="bottom">
      <div class="px-16 py-8">
        <van-button size="small" type="primary" class="h-28" @click="handleDateConfirm2(null)">清空</van-button>
      </div>
      <DatetimePicker
        title=""
        type="date"
        :min-date="minDate2"
        :maxDate="maxDate2"
        v-model="currentDate2"
        show-toolbar
        @confirm="handleDateConfirm2"
        @cancel="showDateRef2 = false"
      />
    </Popup>

    <!-- 按钮栏 -->
    <div>
      <div class="truck-btn-box">
        <Button size="large" :disabled="!next" @click="handleToNext" type="primary">下一步</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted } from '@vue/composition-api';
import TosUploader from '@/components/uploader/uploader.vue';
import TimeMachine from '@/components/TimeMachine';
import { Button, Popup, Toast, DatetimePicker } from 'vant';
import { apiOcrDriverLicences } from '@/api/ocr';
import { useDriverVerifiedStore } from '@/store/driverVerfied';
import { useUserStore } from '@/store/user';
import { driverStepFormTwo, getErrorByRules } from '../constants';
import ChecksPorps from './ChecksPorps';

import { buildFormByAuthModeFields } from '@/utils/authMode';

import { flatten } from 'lodash-es';

import { driverLicenseDateRangeValidate, isValidEndDate } from '../date-validate';
import dayjs from 'dayjs';
import { parseOcrDate, isExpiredDay } from '@/utils/validateTimeRanges';
import { formatToDateTime } from '@wlhy-web-lib/shared';

export default defineComponent({
  name: 'customer',
  components: {
    Button,
    TosUploader,
    TimeMachine,
    Popup,
    ChecksPorps,
    DatetimePicker
  },
  setup(_, ctx) {
    // 如果本地缓存有数据，从缓存中获取
    const userVerifiedStore = useDriverVerifiedStore();
    const driverLicenseFileList = ref([]);
    const userInfoStore = useUserStore();
    const userVertifiedRef = computed(() => userVerifiedStore.verifiedInfo);
    const timeDateRef = reactive({
      startDate: new Date(+userVerifiedStore.verifiedInfo.driverLicenseStartDate) || new Date(),
      endDate: new Date(+userVerifiedStore.verifiedInfo.driverLicenseEndDate) || new Date()
    });
    const formRules = driverStepFormTwo(timeDateRef, userInfoStore.showReviewKeys);
    // 如果是认证过然后修改认证 【姓名、手机号、身份证号】不可编辑
    buildFormByAuthModeFields(formRules, userVerifiedStore.authModeFields);
    const FormObj = reactive(formRules);

    if (!userInfoStore.showReviewKeys) {
      const hiddenKeys = [
        'driverLicenseAnnualReviewDate',
        'driverLicenseInitialIssueDate',
        'fileNumber',
        'driverLicenseAddress'
      ];
      hiddenKeys.forEach(key => {
        const obj = FormObj[key];
        if (obj) {
          obj.hidden = true;
        }
      });
    }
    const disabled = ref(false);
    const next = ref(true);

    // 用computed有问题
    // const userVertifiedRef = ref(cloneDeep(userVerifiedStore.verifiedInfo));

    // 优先从缓存中取数据

    const validateForm = async (...params) => {
      const form = ctx.refs.form;
      if (form) {
        try {
          const flag = await form.validate(...params);
          return true;
        } catch (e) {
          return false;
        }
      }
      return true;
    };
    const currentDate = ref(new Date());
    const maxDate = ref(new Date('2199/12/31'));
    const minDate = ref(new Date('1970/01/02'));
    const showDateRef = ref(false);
    const handleDateConfirm = e => {
      userVerifiedStore.updateInfo({
        driverLicenseAnnualReviewDate: e ? formatToDateTime(e, 'yyyy-MM-dd') : null
      });
      showDateRef.value = false;
      validateForm('driverLicenseAnnualReviewDate');
    };

    const currentDate2 = ref(new Date());
    const maxDate2 = ref(new Date('2199/12/31'));
    const minDate2 = ref(new Date('1970/01/02'));
    const showDateRef2 = ref(false);
    const handleDateConfirm2 = e => {
      userVerifiedStore.updateInfo({
        driverLicenseInitialIssueDate: e ? formatToDateTime(e, 'yyyy-MM-dd') : null
      });
      showDateRef2.value = false;
      validateForm('driverLicenseInitialIssueDate');
    };

    onMounted(() => {
      // 如果缓存中有图片需要进行回填
      if (userVertifiedRef?.value?.licenseUrl) {
        driverLicenseFileList.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.licenseUrl || '',
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
      if (!userVerifiedStore.verifiedInfo.driverLicenseNumber && userInfoStore?.userInfo?.realNameAuthVO?.idCard) {
        userVerifiedStore.updateInfo({
          driverLicenseNumber: userInfoStore?.userInfo?.realNameAuthVO?.idCard
        });
      }
      if (
        !FormObj.driverLicenseAnnualReviewDate.hidden &&
        userVerifiedStore?.verifiedInfo?.driverLicenseAnnualReviewDate
      ) {
        currentDate.value = new Date(dayjs(userVerifiedStore?.verifiedInfo?.driverLicenseAnnualReviewDate).valueOf());
      }
      if (
        !FormObj.driverLicenseInitialIssueDate.hidden &&
        userVerifiedStore?.verifiedInfo?.driverLicenseInitialIssueDate
      ) {
        currentDate2.value = new Date(dayjs(userVerifiedStore?.verifiedInfo?.driverLicenseInitialIssueDate).valueOf());
      }
    });

    const handlePopConfirm = (e, typeName) => {
      userVerifiedStore.updateInfo({
        [typeName]: e
      });
    };

    const showPopup = key => {
      if (key === 'driverLicenseAnnualReviewDate') {
        showDateRef.value = true;
      } else if (key === 'driverLicenseInitialIssueDate') {
        showDateRef2.value = true;
      } else {
        FormObj[key].visible = true;
      }
    };
    async function afterUploadedDriverCard(file) {
      file.status = 'uploading';
      file.message = '识别中';
      disabled.value = true;
      try {
        const res = await apiOcrDriverLicences({ imageUrl: file.url });
        const { data } = res;
        if (data) {
          const { sealOrganize, validDate, vehicleType, cardNumber, expireDate } = data;

          const sDate = parseOcrDate(validDate);
          const eDate = parseOcrDate(expireDate);
          if (sDate && eDate) {
            timeDateRef.startDate = new Date(sDate);
            timeDateRef.endDate = new Date(eDate);
          }
          // driveType
          const typeList = flatten(FormObj.driveType.options);
          const driveTypes = typeList.reduce((arr, type) => {
            if (vehicleType.includes(type)) {
              arr.push(type);
            }
            return arr;
          }, []);

          userVerifiedStore.updateInfo({
            driveType: driveTypes.join(','),
            driverLicenseIssueOrganization: sealOrganize,
            driverLicenseNumber: cardNumber
          });
        }
      } catch (error) {
        console.log(error);
        // isErrorImage.value = true;
      } finally {
        disabled.value = false;
        file.status = 'done';
      }
    }

    return {
      next,
      FormObj,
      showPopup,
      disabled: false,
      userVertifiedRef,
      timeDateRef,
      driverLicenseFileList,
      afterUploadedDriverCard,
      handlePopConfirm,
      handleDateConfirm,
      showDateRef,
      currentDate,
      minDate,
      maxDate,
      showDateRef2,
      currentDate2,
      minDate2,
      maxDate2,
      handleDateConfirm2,
      handleToNext: () => {
        validateForm();
        if (!driverLicenseFileList.value[0]?.fileName) {
          Toast.fail('图片信息不能为空!');
          return;
        }

        // if (isErrorImage.value) {
        //   Toast.fail('请上传正确的驾驶证图片!');
        //   return;
        // }

        if (userVerifiedStore.authModeFields.driverLicenseNumber) {
          let err1 = '';
          const val1 = userVertifiedRef.value.driverLicenseNumber;
          if (!val1) {
            err1 = '请填写驾驶证编号';
          } else if (val1.length < 6 || val1.length > 20) {
            err1 = '驾驶证编号格式错误';
          }
          if (err1) {
            Toast.fail(err1);
            return;
          }
        }

        if (userVerifiedStore.authModeFields.driveType && !userVertifiedRef.value.driveType) {
          Toast.fail('请填写准驾车型!');
          return;
        }

        if (userVerifiedStore.authModeFields.driverLicenseIssueOrganization) {
          if (!userVertifiedRef.value.driverLicenseIssueOrganization) {
            Toast.fail('请填写发证机关!');
            return;
          }
          if (!/^\s*[\u4e00-\u9fa5]{5,50}\s*$/.test(userVertifiedRef.value.driverLicenseIssueOrganization)) {
            Toast.fail('发证机关必须为5-50位的中文字符!');
            return;
          }
        }

        // 判断驾驶证有效期
        if (userVerifiedStore.authModeFields.driverLicenseEndDate) {
          const driverLicenseStartDate = dayjs(timeDateRef.startDate).valueOf();
          const driverLicenseEndDate = dayjs(timeDateRef.endDate).valueOf();
          if (driverLicenseStartDate === 0 || (driverLicenseStartDate && driverLicenseStartDate < 0)) {
            Toast.fail('驾驶证有效期不符合规则!');
            return;
          }
          if (
            !driverLicenseDateRangeValidate(driverLicenseStartDate, driverLicenseEndDate) ||
            !isValidEndDate(driverLicenseStartDate, driverLicenseEndDate, 'driverLicense')
          ) {
            Toast.fail('驾驶证有效期不符合规则!');
            return;
          }
        }

        if (!FormObj.driverLicenseAnnualReviewDate.hidden) {
          const { driverLicenseAnnualReviewDate } = userVerifiedStore.verifiedInfo;
          if (FormObj.driverLicenseAnnualReviewDate.required && !driverLicenseAnnualReviewDate) {
            Toast.fail('请选择驾驶证年审有效期!');
            return;
          }
          if (driverLicenseAnnualReviewDate && isExpiredDay(driverLicenseAnnualReviewDate)) {
            Toast.fail('驾驶证年审已过期!');
            return;
          }
        }
        if (!FormObj.driverLicenseInitialIssueDate.hidden) {
          const { driverLicenseInitialIssueDate } = userVerifiedStore.verifiedInfo;
          if (FormObj.driverLicenseInitialIssueDate.required && !driverLicenseInitialIssueDate) {
            Toast.fail('请选择初次领证日期!');
            return;
          }
          const errMsg = getErrorByRules(FormObj.driverLicenseInitialIssueDate.rules, driverLicenseInitialIssueDate);
          if (errMsg) {
            Toast.fail(errMsg);
            return;
          }
        }
        if (!FormObj.fileNumber.hidden) {
          const { fileNumber } = userVerifiedStore.verifiedInfo;
          const errMsg = getErrorByRules(FormObj.fileNumber.rules, fileNumber);
          if (errMsg) {
            Toast.fail(errMsg);
            return;
          }
        }

        if (!FormObj.driverLicenseAddress.hidden) {
          const { driverLicenseAddress } = userVerifiedStore.verifiedInfo;
          const errMsg = getErrorByRules(FormObj.driverLicenseAddress.rules, driverLicenseAddress);
          if (errMsg) {
            Toast.fail(errMsg);
            return;
          }
        }

        userVerifiedStore.updateInfo({
          licenseUrl: driverLicenseFileList?.value[0]?.fileName,
          driverLicenseStartDate: dayjs(timeDateRef.startDate).valueOf(),
          driverLicenseEndDate: dayjs(timeDateRef.endDate).valueOf(),
          step: 2
        });
        userVerifiedStore.saveInfo();
        // } else {
        //   Toast.fail('驾驶证起止日期不合法!');
        // }
      }
    };
  }
});
</script>
<style lang="less" scoped>
@import url('../styles/step2.less');
</style>
<style lang="less">
.drivers-step2-content {
  .driver-card-wrap {
    padding: 0.08rem 0.16rem;
    .driver-card {
      width: 3.27rem;
      height: 1.95rem;
      background: #f6f8fa;
    }
  }
}
</style>
