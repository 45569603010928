<template>
  <div class="checksprops-wraper pa-16">
    <div class="fw-500 text-center flex justify-space-between font-18 mb-24">
      <div @click="handleCancel" class="text-primary fw-300">取消</div>
      <div>{{ title }}</div>
      <div
        @click="handleOk"
        :class="selectTypes.length > 0 || showClear ? 'text-primary' : 'text-muted'"
        class=" fw-300"
      >
        确定
      </div>
    </div>
    <div class="text-muted mt-24 mb-16 d-flex">常用准驾车型</div>
    <div class="check-content flex flex-wrap justify-space-between">
      <div
        @click="handleCheck(item)"
        v-for="item in columns[0]"
        :key="item"
        class="check-tag fw-500 font-16 lh-36 mb-16"
        :class="{ checked: selectTypes.includes(item) }"
      >
        {{ item }}
      </div>
    </div>
    <div class="d-flex flex-wrap justify-space-between">
      <div
        @click="handleCheck(item)"
        v-for="item in columns[1]"
        :key="item"
        class="check-tag fw-500 font-16 lh-36 mb-16"
        :class="{ checked: selectTypes.includes(item) }"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, nextTick, ref } from '@vue/composition-api';
import { Toast } from 'vant';
export default defineComponent({
  name: 'CheckPorps',
  model: {
    props: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: () => []
    },
    tagWidth: {
      type: String,
      default: '0.72rem'
    },
    title: {
      type: String,
      default: ''
    },
    columns: {
      type: Array,
      default: () => []
    },
    showClear: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Toast
  },
  setup(props, ctx) {
    const selectTypes = ref(props?.value?.length === 0 ? [] : props.value.split(','));
    const handleOk = () => {
      if (selectTypes.value.length > 0 || props.showClear) {
        ctx.emit('confirm', selectTypes.value.join(','));
        nextTick(() => {
          ctx.emit('cancel');
        });
      } else {
        Toast.fail('请选择准驾车型!');
      }
    };
    const handleCheck = item => {
      // 数组中加一个或者减一个
      if (selectTypes.value.includes(item)) {
        selectTypes.value.splice(
          // 从数组中删除item 项
          selectTypes.value.findIndex(data => data === item),
          1
        );
        // 含有的删除
      } else {
        selectTypes.value = [...selectTypes.value, item];
      }
    };

    return {
      selectTypes,
      handleOk,
      handleCheck,
      handleCancel: () => {
        ctx.emit('cancel');
      }
    };
  }
});
</script>
<style lang="less">
.checksprops-wraper {
  .check-tag {
    text-align: center;
    width: 0.72rem;
    height: 0.36rem;
    border: 1px solid rgba(188, 188, 188, 0.5);
    border-radius: 5px;
    &.colorWhite {
      color: white;
    }
    &.checked {
      background: #0076ff;
      color: white;
    }
  }
  .check-content {
    border-bottom: 1px solid rgba(188, 188, 188, 0.5);
    margin-bottom: 0.16rem;
  }
}
</style>
